<template>
  <div>
    <embed :src="url" type="application/pdf" id="embed-pdf" width="100%" />
  </div>
  <!-- <PDFViewer :source="url"  :download="{filename:'Test'}" style="height: 100vh; width: 100vw" @download="handleDownload"></PDFViewer> -->
</template>
<script>
import PDFViewer from "pdf-viewer-vue/dist/vue2-pdf-viewer";
export default {
  components: { PDFViewer },
  data() {
    return {
      url: "",
    };
  },
  created() {
    if (this.$route.params.file.includes("terms")) {
      if (this.$route.params.file.includes("de")) {
        this.url = "/files/terms_de.pdf";
      } else {
        this.url = "/files/terms_eng.pdf";
      }
    } else if (this.$route.params.file.includes("risk")) {
      if (this.$route.params.file.includes("de")) {
        this.url = "/files/risk_de.pdf";
      } else {
        this.url = "/files/risk_en.pdf";
      }
    }

    //
  },
  methods: {
    handleDownload() {},
  },
};
</script>
<style>
</style>